import React, { useRef } from 'react';
import handleViewport from 'react-in-viewport';

import styles from './FeatureVideo.module.scss';

interface FeatureVideoProps {
  video: string;
  placeholder: string;
}

interface HandleViewportProp {
  inViewport: boolean;
  forwardedRef: React.Ref<HTMLDivElement>;
}

const FeatureVideo: React.FC<FeatureVideoProps & HandleViewportProp> = ({
  inViewport, forwardedRef, video, placeholder,
}) => {
  const videoRef = useRef<HTMLVideoElement>() as React.MutableRefObject<HTMLVideoElement>;

  // start the video when it's in viewport
  if (videoRef.current) {
    if (inViewport) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }

  return (
    <div className={styles.video} ref={forwardedRef}>
      <video
        loop
        muted
        playsInline
        ref={videoRef}
        style={{ backgroundImage: `url(${placeholder})` }}
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default handleViewport<FeatureVideoProps>(FeatureVideo, { threshold: 0.9 });
