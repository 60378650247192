import { Link } from 'gatsby';
import * as React from 'react';

import logo from 'images/albums-logo.svg';

import styles from './Logo.module.scss';

const AlbumsLogo: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...props }) => (
  // eslint-disable-next-line
  <div {...props}>
    <Link to="/">
      <img className={styles.logo} src={logo} alt="Albums" />
    </Link>
  </div>
);

export default AlbumsLogo;
