import React from 'react';

import styles from './FeatureContent.module.scss';

const FeatureContent: React.FC = ({ children }) => (
  <div className={styles.content}>
    {children}
  </div>
);

export default FeatureContent;
