import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import playIcon from './play.svg';

import styles from './PlayVideoButton.module.scss';

interface PlayVideoButtonProps {
  playVideo: () => void;
}
const PlayVideoButton: React.FC<PlayVideoButtonProps> = ({ playVideo }) => (
  <span
    role="button"
    tabIndex={0}
    className={styles.playVideo}
    onKeyPress={(): void => playVideo()}
    onClick={(): void => playVideo()}
  >
    <FormattedMessage id="components.slider.watchVideo" />
    {' '}
    <img src={playIcon} alt="" className={styles.icon} />
  </span>
);

export default PlayVideoButton;
