import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';
import { useCookies } from 'react-cookie';

import { detectMobileOs } from 'helpers/common';

import PlayIcon from 'images/play.svg';

import AlbumsLogo from '../AlbumsLogo';
import Button from '../Button';
import Modal from '../Modal';

import styles from './KeyNoteButton.module.scss';

const KeyNoteButton: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const [cookies, setCookie] = useCookies();
  const [isOpen, setIsOpen] = React.useState(false);

  function setCoockie(): void {
    if (!cookies.keynote) {
      const expireDay = new Date();
      const days = 30;
      expireDay.setDate(expireDay.getDate() + days);

      setCookie('keynote', true, { path: '/', expires: expireDay });
    }

    setIsOpen(false);
  }

  React.useEffect(() => {
    if (!cookies.keynote) {
      setIsOpen(true);
    }
  }, [cookies.keynote, setIsOpen]);

  const os = detectMobileOs();
  const ifreamHeight = os === 'other' ? 360 : 170;

  return (
    <>
      {/* eslint-disable-next-line */}
      <div {...props} className={styles.keyNoteButton}>
        <Button onClick={(): void => setIsOpen(true)}>
          <img src={PlayIcon} alt="albums" />
          {' '}
          <FormattedMessage id="components.keynote.view_keynote" />
        </Button>
      </div>
      {isOpen
        && (
          <Modal setIsModalActive={setCoockie} className={styles.modal}>
            <AlbumsLogo id={styles.logo} />
            <iframe
              width="100%"
              height={ifreamHeight}
              src="https://www.youtube.com/embed/BnrLl5QdHCQ"
              frameBorder="0"
              title="albums launch"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <span className={styles.modalText}><FormattedMessage id="components.keynote.view_keynote" /></span>
          </Modal>
        )}
    </>
  );
};

export default KeyNoteButton;
