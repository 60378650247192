import * as React from 'react';

import ModalDescription from './ModalDescription';
import ModalErrorMessage from './ModalErrorMessage/index';
import ModalTitle from './ModalTitle';

import styles from './Modal.module.scss';

interface ModalProps {
  setIsModalActive: (item: boolean) => void;
  className?: string;
}
export interface ModalComponent extends React.FC<ModalProps> {
  Title: typeof ModalTitle;
  Description: typeof ModalDescription;
  ErrorMessage: typeof ModalErrorMessage;
}

const Modal: ModalComponent = ({ children, className, setIsModalActive }) => (
  <>
    <div className={styles.modal}>
      <div className={`${styles.modalBox} ${className || ''}`}>
        <button
          className={styles.modalCloseBtn}
          type="button"
          aria-label="close button"
          onClick={(): void => setIsModalActive(false)}
        />
        {children}
      </div>
    </div>
  </>
);

Modal.Title = ModalTitle;
Modal.Description = ModalDescription;
Modal.ErrorMessage = ModalErrorMessage;

export default Modal;
