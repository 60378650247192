import * as React from 'react';

import styles from '../Modal.module.scss';

export interface ModalTitleProps {
    children: React.ReactNode;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ children }) => (
  <span className={styles.modalTitle}>
    {children}
  </span>
);

export default ModalTitle;
