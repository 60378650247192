import classNames from 'classnames';
import React from 'react';

import FeatureContent from './FeatureContent';
import FeatureDescription from './FeatureDescription';
import FeatureKeywords from './FeatureKeywords';
import FeatureTitle from './FeatureTitle';
import FeatureVideo from './FeatureVideo';

import styles from './Feature.module.scss';

interface FeatureProps {
  mirrored?: boolean;
}

export interface FeatureComponent extends React.FC<FeatureProps> {
  Content: typeof FeatureContent;
  Title: typeof FeatureTitle;
  Description: typeof FeatureDescription;
  Video: typeof FeatureVideo;
  Keywords: typeof FeatureKeywords;
}

const Feature: FeatureComponent = ({ children, mirrored = false }) => (
  <div className={classNames(styles.container, { [styles.mirrored]: mirrored })}>
    <div className={styles.feature}>
      {children}
    </div>
  </div>
);

Feature.Content = FeatureContent;
Feature.Title = FeatureTitle;
Feature.Description = FeatureDescription;
Feature.Video = FeatureVideo;
Feature.Keywords = FeatureKeywords;

export default Feature;
