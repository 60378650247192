import React, { useRef, useState } from 'react';

const useSliderVideo = (): [
  React.MutableRefObject<HTMLVideoElement>,
  (video: string) => void,
  () => void,
  boolean
] => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>() as React.MutableRefObject<HTMLVideoElement>;

  const playVideo = (video: string): void => {
    setVideoPlaying(true);
    if (videoRef.current) {
      videoRef.current.src = video;
      videoRef.current.play();
    }
  };

  const stopVideo = (): void => {
    setVideoPlaying(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return [videoRef, playVideo, stopVideo, videoPlaying];
};

export default useSliderVideo;
