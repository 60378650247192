import React from 'react';
import { Helmet } from 'react-helmet';

import HomeFeatures from 'components/HomeFeatures';
import Slider from 'components/Slider';
import Layout from 'components/layout';

const IndexPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Home</title>
      <meta name="og:title" content="Home" />
      <meta name="twitter:title" content="Home" />
    </Helmet>
    <Slider />
    <HomeFeatures />
  </Layout>
);

export default IndexPage;
