import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import Keyword from 'components/UI/Keyword';

import Feature from './Feature';
import video1 from './media/feature-1.mp4';
import video2 from './media/feature-2.mp4';
import video3 from './media/feature-3.mp4';
import placeholder1 from './media/placeholder-feature-1.jpg';
import placeholder2 from './media/placeholder-feature-2.jpg';
import placeholder3 from './media/placeholder-feature-3.jpg';

import styles from './HomeFeatures.module.scss';

const HomeFeatures: React.FC = () => (
  <section className={styles.container}>
    <Feature>
      <Feature.Content>
        <Feature.Title>
          <FormattedMessage id="components.homeFeatures.feature_1.title" />
        </Feature.Title>
        <Feature.Description>
          <FormattedMessage id="components.homeFeatures.feature_1.description" />
        </Feature.Description>
        <Feature.Keywords>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_1.keywords.keyword_1" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_1.keywords.keyword_2" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_1.keywords.keyword_3" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_1.keywords.keyword_4" />
          </Keyword>
        </Feature.Keywords>
      </Feature.Content>
      <Feature.Video video={video1} placeholder={placeholder1} />
    </Feature>

    <Feature mirrored>
      <Feature.Content>
        <Feature.Title>
          <FormattedMessage id="components.homeFeatures.feature_2.title" />
        </Feature.Title>
        <Feature.Description>
          <FormattedMessage id="components.homeFeatures.feature_2.description" />
        </Feature.Description>
        <Feature.Keywords>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_2.keywords.keyword_1" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_2.keywords.keyword_2" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_2.keywords.keyword_3" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_2.keywords.keyword_4" />
          </Keyword>
        </Feature.Keywords>
      </Feature.Content>
      <Feature.Video video={video2} placeholder={placeholder2} />
    </Feature>

    <Feature>
      <Feature.Content>
        <Feature.Title>
          <FormattedMessage id="components.homeFeatures.feature_3.title" />
        </Feature.Title>
        <Feature.Description>
          <FormattedMessage id="components.homeFeatures.feature_3.description" />
        </Feature.Description>
        <Feature.Keywords>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_3.keywords.keyword_1" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_3.keywords.keyword_2" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_3.keywords.keyword_3" />
          </Keyword>
          <Keyword>
            <FormattedMessage id="components.homeFeatures.feature_3.keywords.keyword_4" />
          </Keyword>
        </Feature.Keywords>
      </Feature.Content>
      <Feature.Video video={video3} placeholder={placeholder3} />
    </Feature>
  </section>
);

export default HomeFeatures;
