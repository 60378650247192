import React from 'react';

import styles from './FeatureTitle.module.scss';

// TODO: first word should be in different color
const FeatureTitle: React.FC = ({ children }) => (
  <h2 className={styles.title}>
    {children}
  </h2>
);

export default FeatureTitle;
