import React from 'react';

import styles from './FeatureKeywords.module.scss';

const FeatureKeywords: React.FC = ({ children }) => (
  <div className={styles.container}>
    {children}
  </div>
);

export default FeatureKeywords;
