import classNames from 'classnames';
import React from 'react';

import arrow from './arrow.svg';

import styles from './ControlButton.module.scss';

interface ControlButtonProps {
  type: 'forward' | 'back';
  onClick: () => void;
}

const ControlButton: React.FC<ControlButtonProps> = ({ type, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(styles.button, styles[type])}
    aria-label="Previous slide"
  >
    <img src={arrow} className={styles.icon} alt="" />
  </button>
);

export default ControlButton;
