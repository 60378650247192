import React from 'react';

import styles from './FeatureDescription.module.scss';

const FeatureDescription: React.FC = ({ children }) => (
  <p className={styles.description}>
    {children}
  </p>
);

export default FeatureDescription;
