import classNames from 'classnames';
import React from 'react';

import video from '../videos/video-1.mp4';

import phone from './images/phone.png';

import styles from './SliderVideo.module.scss';

interface SliderVideoProps {
  playVideo: boolean;
  onVideoStop: () => void;
}

const SliderVideo = React.forwardRef<HTMLVideoElement, SliderVideoProps>(
  (
    { playVideo, onVideoStop },
    ref: React.Ref<HTMLVideoElement>,
  ) => (
    <div className={classNames(styles.container, { [styles.playing]: playVideo })}>
      <span
        role="button"
        tabIndex={0}
        aria-label="Close video"
        className={styles.close}
        onClick={(): void => onVideoStop()}
        onKeyPress={(): void => onVideoStop()}
      />

      <div className={styles.videoContainer}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          playsInline
          ref={ref}
          onEnded={(): void => onVideoStop()}
          className={styles.video}
          src={video}
        />
        <img src={phone} alt="" className={styles.frame} />
      </div>
    </div>
  ),
);

export default SliderVideo;
