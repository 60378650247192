import * as React from 'react';

import styles from '../Modal.module.scss';

export interface ModalDescriptionProps {
    children: React.ReactNode;
}

const ModalDescription: React.FC<ModalDescriptionProps> = ({ children }) => (
  <p className={styles.modalDescription}>
    {children}
  </p>
);

export default ModalDescription;
