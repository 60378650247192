import classNames from 'classnames';
import React from 'react';

import styles from './Pagination.module.scss';

interface PaginationProps {
  currentSlide: number;
  slideCount: number;
  goToSlide: (slide: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentSlide, slideCount, goToSlide }) => (
  <div className={styles.paginationContainer}>
    {[...Array(slideCount)].map((value, index) => (
      <div
        className={styles.dotContainer}
        onClick={(): void => goToSlide(index)}
        onKeyPress={(): void => goToSlide(index)}
        role="button"
        tabIndex={0}
        aria-label={`Go to slide ${index}`}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        <div className={classNames(styles.dot, { [styles.active]: currentSlide === index })} />
      </div>
    ))}
  </div>
);

export default Pagination;
