import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useState } from 'react';
import SliderComponent from 'react-perfect-slider';

import InstallButton from 'components/InstallButton';
import KeyNoteButton from 'components/UI/KeyNoteButton';

import ControlButton from './ControlButton';
import Pagination from './Pagination';
import PlayVideoButton from './PlayVideoButton';
import SliderVideo from './SliderVideo';
import useSliderVideo from './SliderVideo/useSliderVideo';
import video1 from './videos/video-1.mp4';
import video2 from './videos/video-2.mp4';
import video3 from './videos/video-3.mp4';

import styles from './Slider.module.scss';

const Slider: React.FC = () => {
  const [videoRef, playVideo, stopVideo, videoPlaying] = useSliderVideo();
  const [autoPlay, setAutoPlay] = useState(true);

  const {
    image1,
    image2,
    image3,
  } = useStaticQuery(
    graphql`
        query {
            image1: file(relativePath: { eq: "slider/image-1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 464) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            image2: file(relativePath: { eq: "slider/image-2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 464) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            image3: file(relativePath: { eq: "slider/image-3.png" }) {
                childImageSharp {
                    fluid(maxWidth: 464) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
        }
    `,
  );

  return (
    <section className={styles.container}>
      <div className={styles.slider}>
        <SliderComponent
          autoplay={autoPlay}
          renderControls={(
            next,
            previous,
            goTo,
            currentSlide,
            totalSlides,
          ): React.ReactNodeArray => [
            <ControlButton
              key="back-button"
              type="back"
              onClick={(): void => {
                setAutoPlay(false);
                previous();
              }}
            />,
            <ControlButton
              key="forward-button"
              type="forward"
              onClick={(): void => {
                setAutoPlay(false);
                next();
              }}
            />,
            <Pagination
              key="forward-pagination"
              currentSlide={currentSlide}
              slideCount={totalSlides}
              goToSlide={(slide): void => {
                setAutoPlay(false);
                goTo(slide);
              }}
            />,
          ]}
        >
          <div className={styles.slideContainer} key={1}>
            <div className={styles.slide}>
              <div className={styles.slideContent}>
                <h2 className={styles.slideTitle}>
                  <FormattedMessage id="components.slider.slide_1.title" />
                </h2>
                <p className={styles.slideDescription}>
                  <FormattedMessage id="components.slider.slide_1.description" />
                </p>

                <PlayVideoButton playVideo={(): void => playVideo(video1)} />

                <div className={styles.slideButtons}>
                  <div className={styles.slideButton}>
                    <InstallButton os="ios">App Store</InstallButton>
                  </div>
                  <div className={styles.slideButton}>
                    <InstallButton os="android">Google play</InstallButton>
                  </div>
                </div>
              </div>
              <div className={styles.slide1Image}>
                <GatsbyImage fluid={image1.childImageSharp.fluid} />
              </div>
            </div>
          </div>

          <div className={styles.slideContainer} key={2}>
            <div className={styles.slide}>
              <div className={styles.slideContent}>
                <h2 className={styles.slideTitle}>
                  <FormattedMessage id="components.slider.slide_2.title" />
                </h2>
                <p className={styles.slideDescription}>
                  <FormattedMessage id="components.slider.slide_2.description" />
                </p>

                <PlayVideoButton playVideo={(): void => playVideo(video2)} />

                <div className={styles.slideButtons}>
                  <div className={styles.slideButton}>
                    <InstallButton os="ios">App Store</InstallButton>
                  </div>
                  <div className={styles.slideButton}>
                    <InstallButton os="android">Google play</InstallButton>
                  </div>
                </div>
              </div>
              <div className={styles.slide2Image}>
                <GatsbyImage fluid={image2.childImageSharp.fluid} />
              </div>
            </div>
          </div>

          <div className={styles.slideContainer} key={3}>
            <div className={styles.slide}>
              <div className={styles.slideContent}>
                <h2 className={styles.slideTitle}>
                  <FormattedMessage id="components.slider.slide_3.title" />
                </h2>
                <p className={styles.slideDescription}>
                  <FormattedMessage id="components.slider.slide_3.description" />
                </p>

                <PlayVideoButton playVideo={(): void => playVideo(video3)} />

                <div className={styles.slideButtons}>
                  <div className={styles.slideButton}>
                    <InstallButton os="ios">App Store</InstallButton>
                  </div>
                  <div className={styles.slideButton}>
                    <InstallButton os="android">Google play</InstallButton>
                  </div>
                </div>
              </div>
              <div className={styles.slide3Image}>
                <GatsbyImage fluid={image3.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </SliderComponent>
      </div>

      <SliderVideo
        playVideo={videoPlaying}
        onVideoStop={(): void => stopVideo()}
        ref={videoRef}
      />
      <KeyNoteButton id={styles.homeKeyNoteButton} />
    </section>
  );
};

export default Slider;
